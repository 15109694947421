@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

  .page {
    @apply relative w-full mt-4 px-4 py-4 lg:!px-0 lg:w-[1024px];



  }

}


.filter-red {
  filter: invert(37%) sepia(93%) saturate(7471%) hue-rotate(356deg) brightness(91%) contrast(135%);
}

.filter-green {
  filter: brightness(0) saturate(100%) invert(42%) sepia(84%) saturate(1898%) hue-rotate(88deg) brightness(108%) contrast(113%);
}

.filter-black {
  filter: brightness(0) saturate(100%) invert(0%) sepia(94%) saturate(7485%) hue-rotate(63deg) brightness(92%) contrast(96%);
}

.filter-white {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(355deg) brightness(106%) contrast(102%);
}

.filter-neutral {
  filter: brightness(0) saturate(100%) invert(26%) sepia(40%) saturate(671%) hue-rotate(198deg) brightness(97%) contrast(81%);
}

.filter-orange {
  filter: brightness(0) saturate(100%) invert(48%) sepia(59%) saturate(2925%) hue-rotate(357deg) brightness(100%) contrast(93%);
}

.filter-blue {
  filter: brightness(0) saturate(100%) invert(54%) sepia(60%) saturate(5170%) hue-rotate(202deg) brightness(98%) contrast(97%);
}

.filter-blue-2 {
  filter: brightness(0) saturate(100%) invert(53%) sepia(93%) saturate(589%) hue-rotate(192deg) brightness(99%) contrast(94%);
}

.filter-teal {
  filter: brightness(0) saturate(100%) invert(67%) sepia(84%) saturate(2598%) hue-rotate(136deg) brightness(95%) contrast(84%);
}

.filter-indigo {
  filter: brightness(0) saturate(100%) invert(41%) sepia(65%) saturate(4361%) hue-rotate(229deg) brightness(103%) contrast(102%);
}
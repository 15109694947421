::-webkit-scrollbar {
  width: 0.2rem;
  height: 0.2rem;
  /*background-color: #f5f5f5; */
}

::-webkit-scrollbar-thumb {
  background-color: #000000;
}

:-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0.2rem rgba(0, 0, 0, 0.3);
  /*background-color: #f5f5f5; */
}


body {
  background-color: white;
}